@use 'assets/styles/functions' as *;
@use 'assets/styles/mixins' as *;
@import '../node_modules/angular-calendar/css/angular-calendar.css';

@import 'assets/styles/variables';
@import 'assets/styles/grid';

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:has(:target) {
  scroll-behavior: smooth;
  scroll-padding-top: 3rem;
}

html,
body {
  -webkit-overflow-scrolling: touch !important;
  overflow: hidden !important;
  height: 100% !important;
}
@supports (-webkit-touch-callout: none) {
  body {
    overflow: auto !important;
  }
}

html {
  hanging-punctuation: first last;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeSpeed;
  font-family: 'Roboto', system-ui, sans-serif;
  overflow: hidden;
}

h1,
h2,
h3 {
  line-height: 1.125;
  text-wrap: balance;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

p {
  text-wrap: pretty;
}

img,
picture {
  display: block;
  max-width: 100%;
  height: auto;
  font-style: italic;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

input,
button,
textarea,
select {
  font: inherit;
}

code,
kbd,
samp {
  font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo,
    Consolas, 'DejaVu Sans Mono', monospace;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.dvm-button-regular {
  .material-symbols-outlined {
    font-size: rem(18);
  }
}

.dvm-button-small {
  .material-symbols-outlined {
    font-size: rem(16);
  }
}

dvm-checkbox {
  .checkmark,
  .checkmark::after {
    box-sizing: initial;
  }
}

.mat-drawer {
  &.overview-drawer {
    width: min(rem(420), 100%);
  }
}

dvm-button[secondary][class^='dvm-button-'],
dvm-button[secondary][class*=' dvm-button-'] {
  outline: 1px solid var(--primary-black-100);
  color: var(--secondary-black);
}

dvm-select {
  .error-message.error-message-visible {
    z-index: 1;
  }
}

dvm-input,
dvm-select {
  .error-message.error-message-visible {
    display: block;
    bottom: rem(-18);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

dvm-input,
dvm-select,
dvm-datepicker,
dvm-textarea {
  .input-wrapper.input-wrapper {
    outline-color: var(--primary-black-100);
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
}

dvm-dialog-content-component {
  .dvm-dialog-content {
    max-height: calc(100vh - 250px);
  }
}

dvm-file-dropper {
  .content {
    .icon {
      margin-bottom: rem(20);
    }
    dvm-text[h4] {
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
    }
  }
}

.dvm-mdc-row .dvm-mdc-cell {
  color: var(--secondary-black);
}

.mat-mdc-snack-bar-label.mdc-snackbar__label {
  padding: 0;
}

.mdc-snackbar {
  .mdc-snackbar__surface {
    padding-right: 0;
  }
}

.cdk-overlay-pane {
  min-width: unset !important;
  max-width: calc(100vw - 40px) !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
