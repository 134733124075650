@use './mixins' as *;
@use './functions' as *;

$grid-columns: 24;

.row {
  display: flex;
  flex-wrap: wrap;
  --gutter-x: 12px;
  --gutter-y: 12px;
  row-gap: var(--gutter-y);
  margin-left: calc(var(--gutter-x) * -0.5);
  margin-right: calc(var(--gutter-x) * -0.5);
}

.col {
  padding-left: calc(var(--gutter-x) * 0.5);
  padding-right: calc(var(--gutter-x) * 0.5);
}

@for $i from 0 through $grid-columns {
  .col-#{$i} {
    flex: 0 0 percentage(calc($i / $grid-columns));
  }
}

//this is for making colums acc. to breakpoints
@mixin make-col($media, $col, $grid-columns) {
  .col-#{$media}-#{$col} {
    width: #{$col * calc(100% / $grid-columns)};
    flex: 0 0 auto;
  }
}

@each $media, $breakpoint in $breakpoints-up {
  @media screen and (min-width: #{$breakpoint}) {
    @for $column from 0 through $grid-columns {
      @include make-col($media, $column, $grid-columns);
    }
  }
}