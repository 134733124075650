.dvm-flex                   { display: flex; }
.dvm-flex-column            { display: flex; flex-direction: column; }
.dvm-flex-align-center      { align-items: center; }
.dvm-flex-align-start       { align-items: flex-start; }
.dvm-flex-align-end         { align-items: flex-end; }
.dvm-flex-justify-center    { justify-content: center; }
.dvm-flex-justify-start     { justify-content: flex-start; }
.dvm-flex-justify-end       { justify-content: flex-end; }
.dvm-grid                   { display: grid; }
.dvm-grid-column            { grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); }
.dvm-grid-row               { grid-template-rows: repeat(auto-fill, minmax(100px, 1fr)); }
.dvm-grid-justify-center    { justify-content: center; }
.dvm-grid-justify-start     { justify-content: start; }
.dvm-grid-justify-end       { justify-content: end; }
.dvm-grid-align-center      { align-items: center; }
.dvm-grid-align-start       { align-items: start; }
.dvm-grid-align-end         { align-items: end; }
.dvm-grid-justify-center    { justify-content: center; }
.dvm-grid-justify-start     { justify-content: start; }
.dvm-grid-justify-end       { justify-content: end; }
.dvm-grid-row-span-1        { grid-row: span 1; }
.dvm-grid-row-span-2        { grid-row: span 2; }
.dvm-grid-row-span-3        { grid-row: span 3; }
.dvm-grid-row-span-full     { grid-row: 1 / -1; }
.dvm-grid-column-span-1     { grid-column: span 1; }
.dvm-grid-column-span-2     { grid-column: span 2; }
.dvm-grid-column-span-3     { grid-column: span 3; }
.dvm-grid-column-span-full  { grid-column: 1 / -1; }
.dvm-margin-top-auto        { margin-top: auto; }
.dvm-margin-bottom-auto     { margin-bottom: auto; }