$border-radius: 4px;
$border-radius-small: spacing(1); 
$border-radius-medium: 6px; 
$border-radius-standard: spacing(2); 
$border-radius-large: spacing(3); 
$border-radius-xlarge: spacing(4); 
$border-radius-mega: spacing(5); 

$generic-border-color: map-get($primary-black-palette, 100);
$generic-border-hover: map-get($primary-black-palette, 200);
$elem-inner-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.20) inset;
$elem-inner-box-shadowx2: 0px 0px 3px 0px rgba(0, 0, 0, 0.40) inset;
$elem-inner-box-shadowx3: 0px 0px 3px 0px rgba(0, 0, 0, 0.60) inset;
$elem-inner-box-shadowx4: 0px 0px 3px 0px rgba(0, 0, 0, 0.80) inset;


:root {
    --dvm-brand-color: #{map-get($primary, dv-black)};
    --dvm-brand-color-accent: #{map-get($primary, primary-blue)};

    --dvm-global-border-radius-small: 4px;
    --dvm-global-border-radius-medium: 6px;
    --dvm-global-border-radius-regular: 8px;
    --dvm-global-border-radius-large: 10px;
    --dvm-global-border-radius-mega: 14px;
    --dvm-global-box-shadow-inset: 0px 0px 3px 0px rgba(0, 0, 0, 0.2) inset;
    --dvm-text-primary: #{map-get($secondary, primary-black)};
    --dvm-text-secondary: #{map-get($secondary, secondary-black)};
    --dvm-text-success: #{map-get($complementary, success)};
    --dvm-text-danger: #{map-get($complementary, danger)};
    --dvm-text-urgent: #{map-get($complementary, urgent)};
    --dvm-text-negative: #{map-get($primary, white)};
    --dvm-text-disabled: #{map-get($primary-black-palette, 100)};
    
    --dvm-surface-primary: #{map-get($primary, white)};
    --dvm-surface-negative: #{map-get($primary, dv-black)};
    --dvm-surface-secondary: #{map-get($secondary, offwhite)};
    --dvm-surface-alternative: #{map-get($primary-blue-palette, 50)};
    --dvm-surface-success: #{map-get($complementary, success)};
    --dvm-surface-danger: #{map-get($complementary, danger)};
    --dvm-surface-urgent: #{map-get($complementary, urgent)};
    --dvm-surface-brand: #{map-get($primary-black-palette, 400)};

    --dvm-border-primary-color: #{map-get($primary-black-palette, 100)};
    --dvm-border-primary-hover: #{map-get($primary-black-palette, 200)};

    --dvm-input-primary-background: var(--dvm-surface-primary);

    --dvm-input-text-size-small: 14px;
    --dvm-input-text-size-regular: 14px;
    --dvm-input-text-size-large: 16px;

    --dvm-input-text-color: var(--dvm-text-primary);
    --dvm-input-text-color-secondary: var(--dvm-text-secondary);
    --dvm-input-text-color-placeholder: #{map-get($primary-black-palette, 200)};

    --dvm-input-border-color: #{map-get($primary-black-palette, 100)};
    --dvm-input-border-color-hover: #{map-get($primary-black-palette, 300)};
    --dvm-input-border-color-focus: #{map-get($primary, primary-blue)};
    --dvm-input-border-color-danger: #{map-get($complementary, danger)};

    --dvm-input-text-color-disabled: #{map-get($primary-black-palette, 300)};
    --dvm-input-background-color-disabled: #{map-get($primary-black-palette, 50)};
    --dvm-input-border-color-disabled: #{map-get($primary-black-palette, 50)};
    --dvm-input-border-radius-small: var(--dvm-global-border-radius-medium);
    --dvm-input-border-radius-regular: var(--dvm-global-border-radius-regular);
    --dvm-input-border-radius-large: var(--dvm-global-border-radius-large);
    --dvm-input-padding-small: 4px 6px;
    --dvm-input-padding-regular: 7px 10px;
    --dvm-input-padding-large: 10px 16px;
    --dvm-input-height-small: 28px;
    --dvm-input-height-regular: 34px;
    --dvm-input-height-large: 40px;

    --dvm-space-xxxs: 2px;
    --dvm-space-xxs: 4px;
    --dvm-space-xs: 8px;
    --dvm-space-sm: 12px;
    --dvm-space-md: 20px;
    --dvm-space-lg: 32px;
    --dvm-space-xl: 40px;
    --dvm-space-xxl: 48px;
    --dvm-space-xxxl: 56px;
    --dvm-space-xxxxl: 64px;
    --dvm-space-xxxxxl: 80px;
}
