@import url('https://fonts.googleapis.com/css?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined&display=swap');

@font-face {
    font-family: 'AgencyFB';
    src: url('/fonts/AgencyFB/AgencyFB.eot') format('embedded-opentype'),
    url('/fonts/AgencyFB/AgencyFB.svg') format('svg'),
    url('/fonts/AgencyFB/AgencyFB.ttf') format('truetype'),
    url('/fonts/AgencyFB/AgencyFB.woff') format('woff'),
    url('/fonts/AgencyFB/AgencyFB.woff2') format('woff2');
}
